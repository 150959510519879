import { Card, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { useState } from "react";
import { Constants } from "../Comman/Constants";

const AboutCards = () => {
  const [cardsVisible, setCardsVisible] = useState(true);

  const navigate = useNavigate();
  const handleShowAboutUs = () => {
    setCardsVisible(false);
    navigate(paths.aboutUs);
  };
  const handleShowTeam = () => {
    setCardsVisible(false);
    navigate(paths.team);
  };

  return (
    <Stack
      direction={"row"}
      gap={3}
      mx={10}
      height={200}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ display: cardsVisible ? "flex" : "none" }}
    >
      <Card
        sx={{
          height: 150,
          width: { md: "35%", xs: "50%" },
          borderRadius: 10,
          border: "1px solid rgb(143, 175, 222)",
          "&:hover": { cursor: "pointer" },
        }}
        onClick={handleShowAboutUs}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-evenly"}
          width={"100%"}
          height={150}
          sx={{
            transition: "background-color 0.5s ease",
            "&:hover": {
              bgcolor: "rgba(142, 174, 222, 0.2)",
            },
          }}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            fontSize={25}
            color={"#194a89"}
            component={motion.div}
            initial={{ x: "50%", opacity: 0 }}
            transition={{
              duration: 1.2,
              type: "tween",
            }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: false, amount: 0.5 }}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            ABOUT
          </Stack>
          <Stack
            justifyContent={"end"}
            alignItems={"end"}
            component={motion.div}
            initial={{ y: "50%", opacity: 0 }}
            transition={{
              duration: 1.2,
              type: "tween",
            }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: false, amount: 0.5 }}
          >
            <img src="/images/nav-design-about.svg" alt="" />
          </Stack>
        </Stack>
      </Card>
      <Card
        sx={{
          height: 150,
          width: { md: "35%", xs: "50%" },
          borderRadius: 10,
          border: "1px solid rgb(143, 175, 222)",
          "&:hover": { cursor: "pointer" },
        }}
        onClick={handleShowTeam}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-evenly"}
          width={"100%"}
          height={150}
          sx={{
            transition: "background-color 0.5s ease",
            "&:hover": {
              bgcolor: "rgba(142, 174, 222, 0.2)",
            },
          }}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            fontSize={25}
            color={"#194a89"}
            component={motion.div}
            initial={{ x: "50%", opacity: 0 }}
            transition={{
              duration: 1.2,
              type: "tween",
            }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: false, amount: 0.5 }}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            TEAM
          </Stack>
          <Stack justifyContent={"end"}
            alignItems={"end"}
            component={motion.div}
            initial={{ y: "50%", opacity: 0 }}
            transition={{
              duration: 1.2,
              type: "tween",
            }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: false, amount: 0.5 }}
          >
            <img src="/images/nav-design-team.svg" alt="" />
          </Stack>
        </Stack>
      </Card>
      {/* <Card sx={{ height: 150, width: "30%", borderRadius: 10, border: '1px solid rgb(143, 175, 222)' }}>
        <Stack
          direction={"row"}
          justifyContent={"space-evenly"}
          width={"100%"}
          height={150}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            fontSize={25}
            color={"#194a89"}
            component={motion.div}
            initial={{ x: "50%", opacity: 0 }}
            transition={{
              duration: 1.2,
              type: "tween",
            }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: false, amount: 0.5 }}
          >
            CAREER
          </Stack>
          <Stack justifyContent={"end"} alignItems={"end"} component={motion.div}
            initial={{ y: "50%", opacity: 0 }}
            transition={{
              duration: 1.2,
              type: "tween",
            }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: false, amount: 0.5 }}>
            <img src="/images/nav-design-career.svg" alt="" />
          </Stack>
        </Stack>
      </Card> */}
    </Stack>
  );
};
export { AboutCards };